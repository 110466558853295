// src/modals/NewCategoryModal.js
import React from 'react';
import { Link } from 'react-router-dom';
import Modal from '../components/Modal';
import { useModal } from '../context/ModalContext';

const NewCategoryModal = ({ categoryName, onFileChange }) => {
  const { hideModal } = useModal();

  const handleFilePickerClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.xls,.xlsx,.csv';
    fileInput.onchange = (e) => {
      onFileChange(e, categoryName);
      hideModal();
    };
    fileInput.click();
  };

  return (
    <Modal title={`Add new category: ${categoryName}`}>
      <div className="modal-content-body">
        <div>
          <span>1. Create a presets for this category: </span>
          <a href={`/presets/${encodeURIComponent(categoryName.toLowerCase())}`} target='_blank'>
            Go to Presets
          </a>
        </div>
        <div>
          <span>2. Upload a file: </span>
          <button className="form-action-button" onClick={handleFilePickerClick}>
            Choose File
          </button>
        </div>
        <div style={{ textAlign: 'right', marginTop: '20px' }}>
          <button className="form-action-button" onClick={hideModal}>Cancel</button>
        </div>
      </div>
    </Modal>
  );
};

export default NewCategoryModal;
