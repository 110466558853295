import React, { useEffect, useState } from 'react';
import Modal from '../components/Modal';
import { useModal } from '../context/ModalContext';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setFramework } from '../slices/dataSlice';
import { toast } from 'react-toastify';
import { useGetDataFileByNameQuery, useUpdateDataFileNameMutation } from '../slices/dataFilesApiSlice';
import { useGetAllCategoryNamesQuery, useGetAllGroupNamesQuery } from '../slices/frameworkGroupAPISlice';
import { TbChevronRight } from 'react-icons/tb';

const EditNameModal = ({ frameworkName }) => {
  const { hideModal } = useModal();
  const [newName, setNewName] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [newGroup, setNewGroup] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updateDataFileName] = useUpdateDataFileNameMutation();

  const { data, isLoading: isLoadingDataFile } = useGetDataFileByNameQuery(frameworkName);
  const { data: categories, isLoading: isLoadingCategories } = useGetAllCategoryNamesQuery();
  const { data: groups, isLoading: isLoadingGroups } = useGetAllGroupNamesQuery();

  useEffect(() => {
    if (!isLoadingDataFile && data) {
      setNewCategory(data.category);
      setNewGroup(data.group);
      setNewName(data.name);
    }
  }, [data, isLoadingDataFile]);

  const handleConfirmedEdit = async () => {
    try {
      await updateDataFileName({ name: frameworkName, newName, newCategory, newGroup }).unwrap();
      dispatch(setFramework(newName));
      toast.success(`${frameworkName} framework renamed to ${newName}`);
      navigate(`/${newName}`);
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
    hideModal();
  };

  if (isLoadingDataFile || isLoadingCategories || isLoadingGroups) {
    return (
      <Modal title="Loading...">
        <div className="loading-container">
          <p>Loading data, please wait...</p>
        </div>
      </Modal>
    );
  }

  return (
    <Modal title={`Edit ${frameworkName}'s location`}>
      <div className="input-container">
        {/* Autocomplete for Category */}
        <input
          className="input-field"
          type="text"
          list="categories"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
        />
        <datalist id="categories">
          {categories?.map((category) => (
            <option key={category} value={category} />
          ))}
        </datalist>
        <TbChevronRight />

        {/* Autocomplete for Group */}
        <input
          className="input-field"
          type="text"
          list="groups"
          value={newGroup}
          onChange={(e) => setNewGroup(e.target.value)}
        />
        <datalist id="groups">
          {groups?.map((group) => (
            <option key={group} value={group} />
          ))}
        </datalist>
        <TbChevronRight />

        {/* Editable Name */}
        <input
          className="input-field"
          type="text"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
        />
      </div>
      <div className="button-container">
        <button className="form-action-button" onClick={handleConfirmedEdit}>
          Save
        </button>
        <button className="form-action-button" onClick={hideModal}>
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default EditNameModal;
