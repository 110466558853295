// src/modals/CynomiModal.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from '../components/Modal';
import '../styles/modals/CynomiTaskModal.css';

// Make sure your custom hook is imported:
import { useGetControlsByTaskIdQuery } from '../slices/dataFilesApiSlice';

const CynomiModal = ({ UID }) => {
  const cynomiCsvData = useSelector((state) => state.data.cynomiCsvData);
  
  // Original logic: find local CSV row
  const matchingData = cynomiCsvData.find((item) => item.ID === UID);

  // Hook to fetch the matched controls from the new endpoint
  const { data: matchedFiles, error, isLoading } = useGetControlsByTaskIdQuery(UID);

  return (
    <Modal title={`UID: ${UID}`}>
      {/* 1) Original CSV-based info */}
      {matchingData ? (
        <div className="modal-content-values">
          <div className="left">
            {Object.entries(matchingData)
              .filter((_, index) => index % 2 === 0)
              .map(([key, value], index) => {
                if (value && value.trim().length > 0) {
                  return (
                    <p key={index}>
                      <strong>{key}:</strong> {value}
                    </p>
                  );
                }
                return null;
              })}
          </div>
          <div className="right">
            {Object.entries(matchingData)
              .filter((_, index) => index % 2 !== 0)
              .map(([key, value], index) => {
                if (value && value.trim().length > 0) {
                  return (
                    <p key={index}>
                      <strong>{key}:</strong> {value}
                    </p>
                  );
                }
                return null;
              })}
          </div>
        </div>
      ) : (
        <p>No data found for UID: {UID}</p>
      )}

      {/* 2) Divider / heading for matched DataFiles */}
      <hr style={{ margin: '1rem 0' }} />
      <h4 style={{ margin: '1rem 0'}}>Related Compliances</h4>
      
      {/* 3) Show load/error states from our query */}
      {isLoading && <p>Loading matched controls...</p>}
      {error && <p style={{ color: 'red' }}>Failed to load matched controls.</p>}

      {/* 4) If we have any matched files, display them nicely */}
      {matchedFiles && matchedFiles.length > 0 && (
        <div className='modal-controls-values' style={{ maxHeight: '400px', overflowY: 'auto' }}>
          {matchedFiles.slice(0, matchedFiles.length / 2).map((df) => (
            <div key={df._id}>
              <p>
                <strong >{df.name}: &nbsp;</strong>
                {df.matchedData.map((control, index) => (
                  <React.Fragment key={index}>
                    <a href={`/${df.name}/${control}`} target='_blank'>{control}</a>
                    {/* Add comma if not last item */}
                    {index < df.matchedData.length - 1 && ', '}
                  </React.Fragment>
                ))}
              </p>
            </div>
          ))}
          {matchedFiles.slice(matchedFiles.length / 2).map((df) => (
            <div key={df._id}>
              <p>
                <strong >{df.name}: &nbsp;</strong>
                {df.matchedData.map((control, index) => (
                  <React.Fragment key={index}>
                    <a href={`/${df.name}/${control}`} target='_blank'>{control}</a>
                    {/* Add comma if not last item */}
                    {index < df.matchedData.length - 1 && ', '}
                  </React.Fragment>
                ))}
              </p>
            </div>
          ))}
        </div>
      )}

      {/* If query returned empty array, show something else */}
      {matchedFiles && matchedFiles.length === 0 && (
        <p>No matching frameworks found for this UID.</p>
      )}
    </Modal>
  );
};

export default CynomiModal;
