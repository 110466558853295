import React, { useEffect, useState } from 'react';
import { filterTasksById } from '../utils/taskUtils';
import DataTable from '../components/DataTable';
import TaskRowCheckBox from '../components/TaskRowCheckBox';
import ScoreFilter from '../components/ScoreFilter';
import HashLoader from "react-spinners/HashLoader";
import { calculateRows } from '../utils/dynamicTaskTableUtils';
import { useGetAllPoliciesQuery, useGetTaskFromDescMutation } from '../slices/featuresApiSlice';
import { useUpdateDataFileMutation, useMatchNextFiveTasksDataFileMutation, useUpdateDataFileStatsMutation } from '../slices/dataFilesApiSlice';
import { TbRefresh, TbRewindForward5 } from 'react-icons/tb';
import Dropdown from './Dropdown';
import { basePolicies, envPolicies } from '../utils/groups';
import { toast } from 'react-toastify';
import { useGetPresetsByCategoryQuery } from '../slices/presetApiSlice';

const TaskRowRenderer = ({
  cell,
  index,
  dataKey,
  selectedRowData,
  cynomiCsvData,
  mapping,
  scoreFilter,
  setScoreFilter,
  handleRowClick,
  addToMappingHandler,
  isEditMode,
  setIsEditMode,
  controlIndex,
  handleInputChange,
  frameworkData,
  refetchFrameworkData,
}) => {
    const [getTaskFromDesc] = useGetTaskFromDescMutation();
    const [updateDataFile] = useUpdateDataFileMutation();
    const [matchNextFiveTasks] = useMatchNextFiveTasksDataFileMutation();
    const [updateDataFileStats] = useUpdateDataFileStatsMutation();
    const { data: presets } = useGetPresetsByCategoryQuery(frameworkData?.category.toLowerCase(), { skip: !frameworkData?.category });
    const [isLoading, setIsLoading] = useState(false);
    const [policies, setPolicies] = useState([]);  
    const [matchByFields, setMatchByFields] = useState([...selectedRowData[selectedRowData.length - 3]]);
    const [newTaskIds, setNewTaskIds] = useState([]);
    const [fileName, setFileName] = useState('');
    const [rowIndex, setRowIndex] = useState([]);
    const [headerRow, setHeaderRow] = useState([]);

    useEffect(() => {
        setFileName(frameworkData.name);
        setRowIndex(frameworkData.data.map(item => item[controlIndex]).indexOf(selectedRowData[controlIndex]));
        setHeaderRow(frameworkData.data[0]);
        setMatchByFields(headerRow.slice(0, -5));
    }, [frameworkData, selectedRowData, controlIndex, headerRow]);

    const { data: policiesData } = useGetAllPoliciesQuery();


    // Handle the checkbox click logic
    const handleCheckboxClick = (task) => {
        if (!isEditMode) {
            setIsEditMode(true); // Enable edit mode if not already
            toast.info("Edit mode Enabled", { autoClose: 2000 })
        }
        addToMappingHandler(task); // Add the task to the mapping
    }

    const handleAITaskRematchClick = async () => {
        setIsLoading(true);
        try {
            // Fetch new matches from AI
            const text = matchByFields.map(field => {
                const index = headerRow.indexOf(field);
                return `${headerRow[index]}: ${selectedRowData[index]}`;
            })
            const preset = presets?.[0];
            
            const response = await getTaskFromDesc({ text, policies, preset }).unwrap();
            
            const newMapping = response.matches.map(msg => JSON.stringify({ ID: msg['task_id'], score: msg['match_score'], reason: msg['reason_to_match'] })).join(', ');
            
            // Update the selected row data with the new mapping
            const newData = [
                ...selectedRowData.slice(0, frameworkData.userMappingIndex - 1),
                newMapping,
                ...selectedRowData.slice(frameworkData.userMappingIndex, selectedRowData.length)
            ];
            
            // Update the data file with the new matches
            await updateDataFile({
                name: fileName,
                index: rowIndex,
                newData
            });

            const newIds = response.matches.map(msg => msg['task_id']);
            console.log(newIds);
            setNewTaskIds(newIds);
            
            // Update the data file stats
            const hasValidStat = Object.values(frameworkData.statistics).some(
                (value) => value !== null
            );
            if (hasValidStat) await updateDataFileStats({ name: fileName });

            // Refetch the framework data
            refetchFrameworkData();

            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching task from description:", error);
            setIsLoading(false);
        }
    };

    const handleNextFiveClick = async () => {
        setIsLoading(true);
        try {
            const { tasks, newTasks } = await matchNextFiveTasks({
                name: fileName,
                index: rowIndex,
                policies: policies.length > 0 ? policies : null,
            }).unwrap();
    
            // Extract new task IDs and update the state
            const newIds = newTasks.map(task => JSON.parse(task).ID);
            setNewTaskIds(newIds);
    
            const hasValidStat = Object.values(frameworkData.statistics).some(value => value !== null);
            if (hasValidStat) await updateDataFileStats({ name: fileName });
    
            refetchFrameworkData();
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching the next five matching tasks:", error);
            setIsLoading(false);
        }
    };
    

    if (dataKey === "AI Match UID") {
        let tableData = [['Select', 'Task UID', 'Task', 'Task Description', 'Policy', <ScoreFilter setScoreFilter={setScoreFilter} scoreFilter={scoreFilter} />, "Reason for Match"]];

        tableData.push(
            ...filterTasksById(selectedRowData[index], cynomiCsvData).map(task => {
                const isNew = newTaskIds.includes(task.UID);
                return [
                    <TaskRowCheckBox
                        ID={task.UID}
                        checked={mapping?.length > 0 && mapping?.split(/\s*,\s*/).includes(task.UID)}
                        addToMappingHandler={() => handleCheckboxClick(task)}
                    />,
                    task.UID,
                    <span className={isNew ? 'new-task' : ''}>{task.Task}</span>,
                    task.Description,
                    task.Policy,
                    task.Score,
                    task.Reason,
                ];
            })
        );        

        return cynomiCsvData && (
        <div className='data-row full-width' key={`${cell} ${index} ${dataKey}`}>
            <div className="table-container">
            {isLoading ? (
                <div className="small-loader-container">
                <HashLoader size={74} color={"#8D8D8D"} />
                </div>
            ) : (
                <DataTable data={tableData} handleRowClick={handleRowClick} />
            )}
            </div>
            {!isLoading && (
                <div className="rematch-controls-container">
                    <div className="dropdown-wrapper">
                        <Dropdown 
                            value={matchByFields} 
                            onChange={setMatchByFields} 
                            title={"Choose fields to match by"} 
                            options={headerRow} 
                            multiple 
                            buttonClassName={'task-rematch-button'} 
                            up
                            notInline
                        />
                    </div>
                
                    <div className="dropdown-wrapper">
                        <Dropdown 
                            value={policies} 
                            onChange={setPolicies} 
                            title={"Choose Policies"} 
                            options={policiesData?.length > 0 ? policiesData : []} 
                            groups={[basePolicies, envPolicies]}
                            multiple 
                            buttonClassName={'task-rematch-button'} 
                            up
                            notInline
                        />
                    </div>
                
                    <div className="submit-buttons">
                        <button
                            className="ai-match-button"
                            onClick={handleAITaskRematchClick}
                        >
                            Rematch All Tasks <TbRefresh size={24} />
                        </button>

                        <button
                            className="ai-match-button"
                            onClick={handleNextFiveClick}
                        >
                            Next 5 Tasks <TbRewindForward5 size={24} />
                        </button>
                    </div>
                </div>                      
            )}
        </div>
        );
    } else if (dataKey === "Predefined UID" || dataKey === "User Mapping" || dataKey === "GPT Match UID") {
        let tableData = [['Select', 'Task UID', 'Task', 'Task Description', 'Policy']];

        tableData.push(
        ...filterTasksById(selectedRowData[index], cynomiCsvData)
            .map(task => [      
                <TaskRowCheckBox
                    ID={task.UID}
                    checked={mapping?.length > 0 && mapping?.split(/\s*,\s*/).includes(task.UID)}
                    addToMappingHandler={() => handleCheckboxClick(task)}
                />,
                task.UID,
                task.Task,
                task.Description,
                task.Policy
            ])
        );

        return cynomiCsvData && (
        <div className='data-row full-width' key={`${cell} ${index} ${dataKey}`}>
            <div className="table-container">
            <DataTable data={tableData} handleRowClick={handleRowClick} />
            </div>
        </div>
        );
    } else {
        const rows = calculateRows(cell, 'FULL');
        return (
        <div
            className={`data-row ${isEditMode && index !== controlIndex ? 'editable' : ''}`}
            key={`${cell} ${index} ${dataKey}`}
            style={{ direction: 'ltr' }} // Ensuring left-to-right direction
        >
            <strong>{dataKey}:</strong>&nbsp;&nbsp;
            {isEditMode && index !== controlIndex ? (
            <textarea
                value={cell}
                onChange={(e) => handleInputChange(e, index)}
                className="editable-textarea"
                rows={rows} // Adjust the number of rows dynamically
            />
            ) : (
            <span>{cell}</span>
            )}
        </div>
        );
    }
};

export default TaskRowRenderer;
