import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setFramework, setCategoryData } from '../slices/dataSlice';
import { useGetFrameworkGroupsByCategoryQuery } from '../slices/frameworkGroupAPISlice';
import { useGetAllPresetCategoriesQuery } from '../slices/presetApiSlice';
import { processFile } from '../utils/fileUtils';
import { useModal } from '../context/ModalContext';
import AccordionGroup from './AccordionGroup';
import NewMappingModal from '../modals/NewMappingModal';
import CategorySection from './CategorySection';
import { transformData } from '../utils/dataTransform';
import NewCategoryModal from '../modals/NewCategoryModal'; // New modal component

const FrameworkList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showModal } = useModal();
  const { frameworkName } = useParams();
  const decodedFrameworkName = decodeURIComponent(frameworkName || '');
  const chosenFramework = useSelector((state) => state.data.framework);
  const categoryDataFromStore = useSelector((state) => state.data.categoryData);

  const [searchQuery, setSearchQuery] = useState('');
  const [expandedGroups, setExpandedGroups] = useState({});
  const [filteredDataByCategory, setFilteredDataByCategory] = useState({});
  
  const [newCategoryName, setNewCategoryName] = useState('');

  // Fetch all framework groups by categories in one go
  const { data: existingCategoryData, isLoading: isCategoryLoading, error } = useGetFrameworkGroupsByCategoryQuery();

  const { data: presetsCategories, isLoading: isPresetLoading } = useGetAllPresetCategoriesQuery();

  const isLoading = isCategoryLoading || isPresetLoading;

  // Transform each category from the retrieved data
  const transformedCategories = useMemo(() => {
    if (!existingCategoryData?.length) return [];

    const missingCategories = presetsCategories?.filter((c) => !existingCategoryData.some(
      (d) => d.category.toLowerCase() === c.toLowerCase())) ?? [];

    const allCategoryData = [
      ...existingCategoryData,
      ...missingCategories.map((category) => ({ category, groups: [] })),
    ];

    return allCategoryData
      .sort((a, b) => a.category.localeCompare(b.category))
      .map(categoryEntry => {
        const { category } = categoryEntry;
        const transformed = categoryEntry
          ? transformData(
              categoryEntry.groups.map((g) => ({
                name: g.group,
                frameworks: g.frameworks,
              }))
            )
          : { groupedItems: [], allItems: [] };

        return {
          // ...cfg,
          name: category.charAt(0).toUpperCase() + category.slice(1),
          category,
          data: categoryEntry,
          transformed,
          isLoading,
          error,
        };
      });

  }, [existingCategoryData, presetsCategories, error]);

  // Set category data in Redux after we receive it
  useEffect(() => {
    transformedCategories.forEach((categoryObj) => {
      const { category, transformed } = categoryObj;
      if (transformed && transformed.allItems) {
        dispatch(setCategoryData({ category, data: transformed }));
      }
    });
  }, [transformedCategories, dispatch]);

  // After setting the data, we want to handle the decodedFrameworkName expansion
  useEffect(() => {
    if (decodedFrameworkName && transformedCategories.length > 0) {
      // Find which category contains the decodedFrameworkName
      transformedCategories.forEach((catObj) => {
        const { transformed } = catObj;
        if (transformed?.groupedItems) {
          let parentGroup = null;
          transformed.groupedItems.forEach((group) => {
            if (group.items.includes(decodedFrameworkName)) {
              parentGroup = group.group;
            }
          });

          if (parentGroup && !expandedGroups[parentGroup]) {
            setExpandedGroups((prev) => ({
              ...prev,
              [parentGroup]: true,
            }));
          }
        }
      });
    }
  }, [decodedFrameworkName, transformedCategories, expandedGroups]);

  const handleItemClick = (itemName, groupName) => {
    navigate(`/${encodeURIComponent(itemName)}`);
    dispatch(setFramework(itemName));
    setExpandedGroups((prev) => ({
      ...prev,
      [groupName]: true,
    }));
  };

  const handleFileChange = (e, category) => {
    const file = e.target.files[0] || e.target.files.item(0);
    if (file) {
      const reader = new FileReader();
      reader.onload = (evt) => {
        const result = processFile(evt, file);
        showModal(<NewMappingModal file={result} category={category}/>);
      };
      reader.readAsArrayBuffer(file);
      e.target.value = null;
    }
  };

  const handleSearchChange = (query) => {
    setSearchQuery(query);

    if (query === '') {
      // Reset filtered data to original data from the store
      const resetData = {};
      Object.keys(categoryDataFromStore).forEach((cat) => {
        resetData[cat] = categoryDataFromStore[cat];
      });
      setFilteredDataByCategory(resetData);
      setExpandedGroups({});
      return;
    }

    const newExpandedGroups = {};
    const updatedFilteredData = {};

    // Filter each category
    Object.entries(categoryDataFromStore).forEach(([cat, data]) => {
      if (!data?.groupedItems) {
        updatedFilteredData[cat] = { groupedItems: [], allItems: [] };
        return;
      }

      const filteredGroupedItems = data.groupedItems
        .map((group) => {
          const groupNameMatches = group.group.toLowerCase().includes(query.toLowerCase());
          if (groupNameMatches) {
            return group;
          } else {
            const filteredItems = group.items.filter((item) =>
              item.toLowerCase().includes(query.toLowerCase())
            );
            return filteredItems.length > 0 ? { ...group, items: filteredItems } : null;
          }
        })
        .filter((g) => g !== null);

      if (filteredGroupedItems.length > 0) {
        updatedFilteredData[cat] = {
          groupedItems: filteredGroupedItems,
          allItems: filteredGroupedItems.flatMap((g) => g.items),
        };
        // Expand all groups with matches
        filteredGroupedItems.forEach((group) => {
          newExpandedGroups[group.group] = true;
        });
      } else {
        updatedFilteredData[cat] = { groupedItems: [], allItems: [] };
      }
    });

    setExpandedGroups(newExpandedGroups);
    setFilteredDataByCategory(updatedFilteredData);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    const resetData = {};
    Object.keys(categoryDataFromStore).forEach((cat) => {
      resetData[cat] = categoryDataFromStore[cat];
    });
    setFilteredDataByCategory(resetData);
    setExpandedGroups({});
  };

  const getCategoryData = (category) => {
    if (searchQuery && filteredDataByCategory[category]) {
      return filteredDataByCategory[category];
    }
    return categoryDataFromStore[category] || { groupedItems: [], allItems: [] };
  };

  // Handler that runs when an accordion is toggled
  const handleAccordionToggle = (index, isOpenNow) => {
    // If the accordion was closed, reset search and expanded groups
    if (!isOpenNow) {
      handleClearSearch();
    }
  };

  const accordionItems = transformedCategories.map(({ name, category, isLoading, error }) => {
    const categoryData = getCategoryData(category);

    return {
      title: name,
      content: (
        <CategorySection
          title={name}
          data={categoryData}
          isLoading={isLoading}
          error={error}
          expandedGroups={expandedGroups}
          setExpandedGroups={setExpandedGroups}
          chosenItem={chosenFramework}
          onItemClick={handleItemClick}
          searchQuery={searchQuery}
          onSearchChange={handleSearchChange}
          onClearSearch={handleClearSearch}
          isOpen={decodedFrameworkName ? false : true}
        />
      ),
    };
  });

  // Get existing category names for validation
  const existingCategoryNames = transformedCategories.map((catObj) => catObj.category.toLowerCase());
  const isAddButtonDisabled = !newCategoryName.trim() || existingCategoryNames.includes(newCategoryName.trim().toLowerCase());

  const handleAddNewCategoryClick = () => {
    showModal(
      <NewCategoryModal
        categoryName={newCategoryName.trim()}
        onFileChange={handleFileChange}
      />
    );
  };

  return (
    <div className="framework-list">
      <AccordionGroup items={accordionItems} onFileUpload={handleFileChange} onToggle={handleAccordionToggle} />
      {!decodedFrameworkName && (
        <div className="new-category-section">
          <input
            type="text"
            placeholder="New category name"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
          />
          <button
            disabled={isAddButtonDisabled}
            onClick={handleAddNewCategoryClick}
          >
            Add New Category
          </button>
        </div>
      )}
    </div>
  );
};

export default FrameworkList;
