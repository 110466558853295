// src/components/Navbar.js
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { TbLogout, TbUpload, TbSun, TbMoon, TbBell, TbPrompt } from 'react-icons/tb';
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useModal } from '../context/ModalContext';
import CynomiFileModal from '../modals/CynomiNewFileModal';
import { setTheme, setNotificationCollapsed, setNotificationShown } from '../slices/uiSlice';
import { useLogoutMutation } from '../slices/usersApiSlice';
import { useUpdateCynomiTasksFileMutation } from '../slices/featuresApiSlice';
import { toast } from 'react-toastify';
import { logout as logoutState } from '../slices/authSlice';
import '../styles/components/Navbar.css';

const Navbar = ({
  allowControlFramework = true,
  pageInfo = null,
  homeLink = '',
  allowNewCynomiFile = true,
  addButton = [],
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showModal } = useModal();
  const [rawFile, setRawFile] = useState(null);
  const theme = useSelector((state) => state.ui.theme);

  const [logout] = useLogoutMutation();
  const [updateCynomiTasksFile, { isLoading, isError, isSuccess }] = useUpdateCynomiTasksFileMutation();

  const cynomiFileInputRef = useRef(null);

  const [cynomiFileInputKey, setCynomiFileInputKey] = useState(Date.now());

  // Notification center state
  const isNotificationCollapsed = useSelector((state) => state.ui.isNotificationCollapsed);
  const isNotificationShown = useSelector((state) => state.ui.isNotificationShown);
  const [isNotificationButtonDisabled, setIsNotificationButtonDisabled] = useState(false);

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  useEffect(() => {
    if (isLoading) {
      toast.info('Uploading file, please wait...');
    } else if (isSuccess) {
      toast.success('File uploaded successfully!');
    } else if (isError) {
      toast.error('Error uploading file, please try again.');
    }
  }, [isLoading, isError, isSuccess]);

  const handleCynomiFileUpload = async (file, mapping) => {
    try {
      await updateCynomiTasksFile({ file: rawFile, mapping }).unwrap();
    } catch (error) {
      // Error handling is already managed in useEffect
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setRawFile(file);
      const reader = new FileReader();
      reader.onload = (evt) => processFile(evt.target.result, file);
      reader.readAsArrayBuffer(file); // Use readAsArrayBuffer
      
      setCynomiFileInputKey(Date.now());
    }
  };

  const processFile = (arrayBuffer, file) => {
    const wb = XLSX.read(arrayBuffer, {
      type: 'array',
      cellDates: true,
      cellNF: true,
      cellText: false,
    });
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    let data = [];
    const range = XLSX.utils.decode_range(ws['!ref']);
    let emptyColumns = new Array(range.e.c + 1).fill(true);

    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        let cell_address = { c: C, r: R };
        let cell_ref = XLSX.utils.encode_cell(cell_address);
        if (ws[cell_ref] && (ws[cell_ref].w || ws[cell_ref].v)) {
          emptyColumns[C] = false;
        }
      }
    }

    for (let R = range.s.r; R <= range.e.r; ++R) {
      let row = [];
      for (let C = range.s.c; C <= range.e.c; ++C) {
        if (!emptyColumns[C]) {
          let cell_address = { c: C, r: R };
          let cell_ref = XLSX.utils.encode_cell(cell_address);
          if (ws[cell_ref]) {
            let value = ws[cell_ref].w || ws[cell_ref].v;
            row.push(String(value));
          } else {
            row.push('');
          }
        }
      }
      if (row.some((cell) => cell !== '')) {
        data.push(row);
      }
    }

    showModal(
      <CynomiFileModal
        file={{ name: file.name, data }}
        handleFileUpload={handleCynomiFileUpload}
      />
    );
  };

  const openCynomiFilePicker = () => {
    cynomiFileInputRef.current.click();
  };

  const handleLogout = async () => {
    try {
      await logout().unwrap();
      localStorage.removeItem('userInfo');
      dispatch(logoutState());
      toast.success('Logged out successfully');
      navigate('/login');
    } catch (err) {
      toast.error('Failed to logout');
    }
  };

  const toggleTheme = useCallback(() => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    dispatch(setTheme(newTheme));
    document.documentElement.setAttribute('data-theme', newTheme);
    localStorage.setItem('theme', newTheme);
  }, [theme, dispatch]);

  // Toggle Notification Center
  const toggleNotificationCenter = () => {
    if (isNotificationButtonDisabled) return;

    setIsNotificationButtonDisabled(true);
    dispatch(setNotificationCollapsed(!isNotificationCollapsed));
    if (!isNotificationShown) {
      dispatch(setNotificationShown(true));
    } else {
      setTimeout(() => {
        dispatch(setNotificationShown(false));
      }, 500);
    }
    setTimeout(() => {
      setIsNotificationButtonDisabled(false);
    }, 500);
  };

  const handleLogoClick = () => {
    navigate(`/${homeLink}`);
  };

  return (
    <div>
      <div className="navbar">
        <div className="navbar-left">
          <div className="navbar-title" onClick={handleLogoClick}>
            MapAI
          </div>
          {pageInfo && <div className="navbar-page-info">{pageInfo}</div>}
        </div>
        <div className="navbar-actions">
          {allowNewCynomiFile && (
            <>
              <button className="navbar-action" onClick={openCynomiFilePicker} aria-label="Update Cynomi Tasks">
                <TbUpload size={22} /> Update Cynomi Tasks
              </button>
              <input
                key={cynomiFileInputKey}
                ref={cynomiFileInputRef}
                type="file"
                accept=".csv"
                className="hidden-input"
                onChange={(e) => handleFileChange(e, 'cynomi')}
              />
            </>
          )}
          {addButton.map((button, index) => (
            <button
              key={index}
              className={`navbar-action ${button.isVisible ? 'visible-button' : 'hidden-button'}`}
              onClick={button.onClick}
            >
              {button.text}
              {button.icon}
            </button>
          ))}
          <button
            className="navbar-action"
            onClick={toggleNotificationCenter}
            aria-label="Notifications"
            disabled={isNotificationButtonDisabled}
          >
            <div className="icon-wrapper">
              <TbBell size={22} />
            </div>
          </button>
          <button className="navbar-action" onClick={toggleTheme} aria-label="Toggle Theme">
            <div className={`icon-wrapper ${theme === 'light' ? 'rotate-0' : 'rotate-180'}`}>
              {theme === 'light' ? <TbMoon size={22} /> : <TbSun size={22} />}
            </div>
          </button>
          <button className="navbar-logout" onClick={handleLogout} aria-label="Logout">
            <TbLogout size={22} />
          </button>
        </div>
      </div>
      {isLoading && <div className="loading-indicator">Loading...</div>}
    </div>
  );
};

export default Navbar;
