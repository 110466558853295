import React, { useState, useRef } from 'react';
import { TbFilePlus, TbTerminal2 } from 'react-icons/tb';
import '../styles/components/AccordionGroup.css';
import { useNavigate } from 'react-router-dom';

const AccordionGroup = ({ items, onFileUpload, onToggle }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const contentRefs = useRef([]);
  const navigate = useNavigate();

  const toggleAccordion = (index, e) => {
    if (e && e.target.nodeName === 'INPUT') {
      e.stopPropagation();
      return;
    }

    const newOpenIndex = openIndex === index ? null : index;
    setOpenIndex(newOpenIndex);
    onToggle && onToggle(index, newOpenIndex === index);
  };

  const openFilePicker = (title, e) => {
    e.stopPropagation();
    e.preventDefault();

    const dataFileInput = document.getElementById(`dataFileInput-${title}`);
    
    dataFileInput.click();
  };

  const getContentHeight = (index) => {
    if (contentRefs.current[index]) {
      return contentRefs.current[index].scrollHeight;
    }
    return 0;
  };

  return (
    <div className="accordion-group">
      {items.map((item, index) => {
        const isOpen = openIndex === index;
        const contentHeight = isOpen ? getContentHeight(index) : 0;
        return (
          <div key={index} className={`accordion-group-section ${isOpen && 'open'}`}>
            <div
              className="accordion-header"
              onClick={(e) => toggleAccordion(index, e)}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') toggleAccordion(index, e);
              }}
            >
              <div className="accordion-title-area">
                <span className="accordion-icon"></span>
                <h4 className="accordion-title">{item.title}</h4>
              </div>
              <div style={{ display: 'flex' ,gap: '10px' }}>
                <button
                  className="icon-button"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent any unwanted accordion behavior
                    const url = `/presets/${item.title?.toLowerCase()}`;
                    window.open(url, "_blank"); // Open the URL in a new tab
                  }}
                  aria-label={`Prompt for ${item.title}`}
                >
                  <TbTerminal2 className="plus-icon" size={26} />
                </button>
                <button
                  className="icon-button"
                  onClick={(e) => openFilePicker(item.title, e)}
                  aria-label={`Upload file for ${item.title}`}
                >
                  <TbFilePlus className="plus-icon" size={26} />
                </button>
              </div>
              <input
                id={`dataFileInput-${item.title}`}
                type="file"
                accept=".xlsx, .xls"
                style={{ display: 'none' }}
                onChange={e => onFileUpload(e, item.title)}
              />
            </div>
            <div
              className="accordion-content"
              ref={(el) => (contentRefs.current[index] = el)}
              style={{ maxHeight: contentHeight }}
            >
              <div style={{ padding: '10px' }}>{item.content}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AccordionGroup;
