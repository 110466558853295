export const transformData = (categoryData) => {
    if (!categoryData) return { groupedItems: [], allItems: [] };
  
    const sortedGroups = [...categoryData].sort((a, b) => a.name.localeCompare(b.name));
    const groupedItems = sortedGroups.map((group) => ({
      id: group._id,
      group: group.name,
      items: [...group.frameworks].sort((a, b) => a.localeCompare(b)), // "frameworks" could be generalized to "items"
    }));
  
    const allItems = groupedItems.reduce((acc, group) => acc.concat(group.items), []);
    return { groupedItems, allItems };
};