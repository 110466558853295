import { apiSlice } from "./apiSlice.js";
import { PRESETS_URL } from "../constants.js"; // Rename PROMPTS_URL to PRESETS_URL

export const presetApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Create a new preset
    createPreset: builder.mutation({
      query: (data) => ({
        url: `${PRESETS_URL}`,
        method: "POST",
        credentials: "include",
        body: data,
      }),
      invalidatesTags: [{ type: "Preset", id: "LIST" }],
    }),

    // Get all presets by category
    getPresetsByCategory: builder.query({
      query: (category) => ({
        url: `${PRESETS_URL}?category=${category}`,
        method: "GET",
        credentials: "include",
      }),
      providesTags: (result, error, category) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Preset", id: _id })),
              { type: "PresetCategory", id: category },
            ]
          : [{ type: "PresetCategory", id: category }],
    }),

    // Fetch all unique preset categories
    getAllPresetCategories: builder.query({
      query: () => ({
        url: `${PRESETS_URL}/categories`,
        method: "GET",
        credentials: "include",
      }),
      providesTags: [{ type: "PresetCategory", id: "LIST" }],
    }),
  }),
});

export const {
  useCreatePresetMutation,
  useGetPresetsByCategoryQuery,
  useGetAllPresetCategoriesQuery,
} = presetApiSlice;
