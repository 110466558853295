import React, { useState, useEffect } from 'react';
import '../styles/components/DynamicTaskTable.css';
import TaskTabs from './TaskTabs';
import { isFullWidth, isHalfWidth } from '../utils/layoutUtils';
import AddTaskForm from './AddTaskForm';
import TaskRowRenderer from './TaskRowRenderer'; 
import { calculateRows } from '../utils/dynamicTaskTableUtils';

const DynamicTaskTable = ({
  containerWidth,
  frameworkData,
  selectedRowData,
  cynomiCsvData,
  mapping,
  scoreFilter,
  setScoreFilter,
  handleRowClick,
  containerRef,
  addToMappingHandler,
  isEditMode,
  refetchFrameworkData,
  setEditedData,
  setIsEditMode,
}) => {
  const [activeTab, setActiveTab] = useState("User Mapping");
  const [editedData, setEditedDataLocal] = useState(selectedRowData);

  useEffect(() => {
    setEditedDataLocal(selectedRowData);
  }, [selectedRowData]);

  useEffect(() => {
    setEditedData(editedData);
  }, [editedData, setEditedData]);

  const handleAddTaskSubmit = (taskData) => {
    addToMappingHandler({ UID: taskData.taskName });
  };

  if (!selectedRowData) return null;

  const dataKeys = frameworkData?.data[0];
  const controlIndex = frameworkData.controlIndex;
  const fullWidthRows = [];
  const halfWidthRows = [];
  const standardRows = [];
  const specialRows = {};

  const handleInputChange = (e, index) => {
    const updatedData = [...editedData];
    updatedData[index] = e.target.value;
    setEditedDataLocal(updatedData);
  };

  dataKeys.forEach((key, index) => {
    const cellContent = editedData[index];
    
    if (key === "Predefined UID" || key === "AI Match UID" || key === "User Mapping") {
      specialRows[key] = (
        <TaskRowRenderer
          cell={cellContent}
          index={index}
          dataKey={key}
          selectedRowData={selectedRowData}
          cynomiCsvData={cynomiCsvData}
          mapping={mapping}
          scoreFilter={scoreFilter}
          setScoreFilter={setScoreFilter}
          handleRowClick={handleRowClick}
          addToMappingHandler={addToMappingHandler}
          isEditMode={isEditMode}
          controlIndex={controlIndex}
          handleInputChange={handleInputChange}
          frameworkData={frameworkData}
          refetchFrameworkData={refetchFrameworkData}
          setIsEditMode={setIsEditMode}
        />
      );
    } else if (isFullWidth(cellContent, containerWidth)) {
      fullWidthRows.push(
        <div
          className={`data-item full-width ${isEditMode && index !== controlIndex ? 'editable' : ''}`}
          key={index}
        >
          <strong>{key}:</strong>&nbsp;&nbsp;
          {isEditMode && index !== controlIndex ? (
            <textarea
              value={cellContent}
              onChange={(e) => handleInputChange(e, index)}
              className="editable-textarea"
              name='full-width'
              rows={calculateRows(cellContent, 'FULL')}
            />
          ) : (
            <span>{cellContent}</span>
          )}
        </div>
      );
    } else if (isHalfWidth(cellContent, containerWidth)) {
      halfWidthRows.push(
        <div
          className={`data-item half-width ${isEditMode && index !== controlIndex ? 'editable' : ''}`}
          key={index}
        >
          <strong>{key}:</strong>&nbsp;&nbsp;
          {isEditMode && index !== controlIndex ? (
            <textarea
              value={cellContent}
              onChange={(e) => handleInputChange(e, index)}
              className="editable-textarea"
              name='half-width'
              rows={calculateRows(cellContent, 'HALF')}
            />
          ) : (
            <span>{cellContent}</span>
          )}
        </div>
      );
    } else {
      standardRows.push(
        <div
          className={`data-item ${isEditMode && index !== controlIndex ? 'editable' : ''}`}
          key={index}
        >
          <strong>{key}:</strong>&nbsp;&nbsp;
          {isEditMode && index !== controlIndex ? (
            <textarea
              value={cellContent}
              onChange={(e) => handleInputChange(e, index)}
              className="editable-textarea"
              name='standard'
              rows={calculateRows(cellContent, 'SMALL')}
            />
          ) : (
            <span>{cellContent}</span>
          )}
        </div>
      );
    }
  });

  const addTaskContent = (
    <div className="add-task-wrapper">
      <AddTaskForm onSubmit={handleAddTaskSubmit} isEditMode={isEditMode} />
    </div>
  );

  return (
    <div className="data-grid" ref={containerRef}>
      {standardRows}
      {halfWidthRows}
      {fullWidthRows}
      <TaskTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        dataKeys={dataKeys}
        specialRows={specialRows}
        handleRowClick={handleRowClick}
        addToMappingHandler={addToMappingHandler}
        isEditMode={isEditMode}
        addTaskContent={addTaskContent}
      />
    </div>
  );
};

export default DynamicTaskTable;
