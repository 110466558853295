import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Extract category from URL
import Navbar from "../components/Navbar";
import NotificationCenter from "../components/NotificationCenter";
import Switch from "../components/Switch";
import Dropdown from "../components/Dropdown";
import { TbArrowRight } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationCollapsed, setNotificationShown } from "../slices/uiSlice";
import {
  useCreatePresetMutation,
  useGetPresetsByCategoryQuery,
} from "../slices/presetApiSlice";
import "../styles/pages/PresetsPage.css";

const PresetsPage = () => {
  const { category } = useParams(); // Get category from URL

  const [name, setName] = useState("My Preset");
  const [nameError, setNameError] = useState("");

  const [rewritingPrompt, setRewritingPrompt] = useState("");
  const [matchingPrompt, setMatchingPrompt] = useState("");
  const [selectedVersion, setSelectedVersion] = useState(null);

  const [optionsState, setOptionsState] = useState({
    extendDescription: true,
    matchAITasks: true,
    mode: "With Exact number (recommended)",
    exactNum: 6,
    minNum: 5,
    maxNum: 20,
  });

  const modeOptions = ["Without Number Bias", "With Min/Max", "With Exact number (recommended)"];

  // Query presets for the requested category
  const {
    data: presets,
    isLoading: isPresetsLoading,
    error: presetsError,
  } = useGetPresetsByCategoryQuery(category);

  // Query presets from 'frameworks' category for fallback
  const {
    data: frameworkPresets,
    isLoading: isFrameworkPresetsLoading,
    error: frameworkPresetsError,
  } = useGetPresetsByCategoryQuery("frameworks");

  const [createPreset, { isLoading: isCreating }] = useCreatePresetMutation();

  const isNotificationCollapsed = useSelector((state) => state.ui.isNotificationCollapsed);
  const isNotificationShown = useSelector((state) => state.ui.isNotificationShown);

  const dispatch = useDispatch();

  // Toggle notification center
  const toggleNotificationCenter = () => {
    dispatch(setNotificationCollapsed(!isNotificationCollapsed));
    if (!isNotificationShown) {
      dispatch(setNotificationShown(true));
    } else {
      setTimeout(() => dispatch(setNotificationShown(false)), 500);
    }
  };

  useEffect(() => {
    if (isPresetsLoading || isFrameworkPresetsLoading) return;
  
    if (presets && presets.length > 0) {
      // If a version is already selected, don't overwrite it
      if (!selectedVersion) {
        setSelectedVersion(presets[0]);
        setName(presets[0].name);
        setRewritingPrompt(presets[0].expandDescriptionPrompt || "");
        setMatchingPrompt(presets[0].topMatchesPrompt || "");
        setOptionsState({
          extendDescription: presets[0].options?.extendDescription ?? true,
          matchAITasks: presets[0].options?.matchAITasks ?? true,
          mode: mapEnumToMode(presets[0].options?.matchesCount),
          exactNum: presets[0].options?.count ?? 6,
          minNum: presets[0].options?.min ?? 5,
          maxNum: presets[0].options?.max ?? 20,
        });
      }
    } else if (!selectedVersion && frameworkPresets?.length > 0) {
      // Load fallback only if no preset is selected
      const fallbackPreset = frameworkPresets.find((p) => p.name === "Framework Prompt v0");
      if (fallbackPreset) {
        const modifiedFallback = { ...fallbackPreset, name: `${category} Prompt v0` };
        setSelectedVersion(modifiedFallback);
        setName(modifiedFallback.name);
        setRewritingPrompt(modifiedFallback.expandDescriptionPrompt || "");
        setMatchingPrompt(modifiedFallback.topMatchesPrompt || "");
        setOptionsState({
          extendDescription: modifiedFallback.options?.extendDescription ?? true,
          matchAITasks: modifiedFallback.options?.matchAITasks ?? true,
          mode: mapEnumToMode(modifiedFallback.options?.matchesCount),
          exactNum: modifiedFallback.options?.count ?? 6,
          minNum: modifiedFallback.options?.min ?? 5,
          maxNum: modifiedFallback.options?.max ?? 20,
        });
      }
    }
    // Don't include `selectedVersion` in the dependency array to prevent loops
  }, [presets, frameworkPresets, isPresetsLoading, isFrameworkPresetsLoading, category]);  

  // Update fields when a version is selected
  useEffect(() => {
    if (selectedVersion) {
      setName(selectedVersion.name);
      setRewritingPrompt(selectedVersion.expandDescriptionPrompt || "");
      setMatchingPrompt(selectedVersion.topMatchesPrompt || "");
      setOptionsState({
        extendDescription: selectedVersion.options?.extendDescription ?? true,
        matchAITasks: selectedVersion.options?.matchAITasks ?? true,
        mode: mapEnumToMode(selectedVersion.options?.matchesCount),
        exactNum: selectedVersion.options?.count ?? 6,
        minNum: selectedVersion.options?.min ?? 5,
        maxNum: selectedVersion.options?.max ?? 20,
      });
    }
  }, [selectedVersion]);

  // Handle name change and validation
  const handleNameChange = (e) => {
    const newName = e.target.value;
    // Validate that the name does not contain dashes
    if (newName.includes("-")) {
      setNameError("Dashes (-) are not allowed in the preset name.");
    } else {
      setNameError("");
    }
    setName(newName);
  };

  // Map UI mode to backend enum
  const mapModeToEnum = (uiMode) => {
    switch (uiMode) {
      case "Without Number Bias":
        return "Without Number";
      case "With Min/Max":
        return "Min/Max";
      case "With Exact number (recommended)":
        return "Exact Num";
      default:
        return "Without Number";
    }
  };

  // map backend enum to UI mode
  const mapEnumToMode = (enumMode) => {
    switch (enumMode) {
      case "Without Number":
        return "Without Number Bias";
      case "Min/Max":
        return "With Min/Max";
      case "Exact Num":
        return "With Exact number (recommended)";
      default:
        return "Without Number Bias";
    }
  };

  // Save preset
  const handleSaveVersion = async () => {
    if (nameError) return; // Do not save if name is invalid
    try {
      const mappedMode = mapModeToEnum(optionsState.mode);

      const options = {
        extendDescription: optionsState.extendDescription,
        matchAITasks: optionsState.matchAITasks,
        matchesCount: mappedMode,
      };

      if (mappedMode === "Exact Num") options.count = optionsState.exactNum;
      else if (mappedMode === "Min/Max") {
        options.min = optionsState.minNum;
        options.max = optionsState.maxNum;
      }

      const newPreset = {
        name,
        category,
        expandDescriptionPrompt: rewritingPrompt,
        topMatchesPrompt: matchingPrompt,
        options,
      };

      await createPreset(newPreset).unwrap();
      alert("Preset saved successfully!");
    } catch (error) {
      console.error("Error saving preset:", error);
      alert("Failed to save the preset! Please try again.");
    }
  };

  const handleToggle = (field) => {
    setOptionsState((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  if (isPresetsLoading || isFrameworkPresetsLoading) return <div>Loading presets...</div>;
  if (presetsError || frameworkPresetsError) return <div>Error loading presets</div>;

  return (
    <div className="prompt-page-container">
      <Navbar pageInfo={`Editing Presets for ${category.toUpperCase()}`} homeLink="" />

      <div className="main-content">
        {/* Timeline Section */}
        <div className="timeline-container framework-list">
          <h3>Version History</h3>
          <ul className="framework-list-body timeline">
            {/* Only show presets from current category in timeline if they exist. 
                If no presets for current category, timeline stays empty (no fallback shown) */}
            {presets && presets.length > 0 ? (
              presets.map((preset) => (
                <li
                  key={preset._id}
                  className={`timeline-item ${selectedVersion?._id === preset._id ? "active" : ""}`}
                  onClick={() => setSelectedVersion(preset)}
                  role="button"
                  tabIndex={0}
                  aria-label={`Select preset ${preset.name}`}
                >
                  <div className="timeline-dot" />
                  <div className="timeline-content">
                    <h3 className="timeline-prompt-name">{preset.name}</h3>
                    <p className="timeline-prompt-meta">
                      <span className="timeline-category">{preset.category}</span>{" "}
                      •{" "}
                      <span className="timeline-date">
                        {new Date(preset.createdAt).toLocaleString()}
                      </span>
                    </p>
                  </div>
                </li>
              ))
            ) : (
              <li className="no-presets-message">
                <p>No presets found for {category}. A fallback preset is loaded.</p>
              </li>
            )}
          </ul>
        </div>

        {/* Preset Editing Section */}
        <div className="main-prompt-sections-container">
          {/* Preset Name Input */}
          <div className="name-input-container">
            <label htmlFor="preset-name" className="name-input-label">
              Preset Name
            </label>
            <input
              id="preset-name"
              type="text"
              value={name}
              onChange={handleNameChange}
              placeholder="Enter preset name"
              className="name-input-field"
              aria-invalid={nameError ? "true" : "false"}
            />
            {nameError && (
              <div className="error-message" role="alert">
                {nameError}
              </div>
            )}
          </div>

          {/* Prompts Section */}
          <div className="prompt-sections-container">
            <div className="prompt-section">
              <h2>
                Edit Prompt: <i>Expanded Description</i>
              </h2>
              <textarea
                className="prompt-textarea"
                value={rewritingPrompt}
                onChange={(e) => setRewritingPrompt(e.target.value)}
                placeholder="Edit the expanded description prompt here..."
                aria-label="Expanded Description Prompt"
              />
            </div>

            <div className="middle-section">
              <p>Embeds expanded description</p>
              <TbArrowRight className="arrow-icon" />
              <p>Fetches 150 top results from Cynomi's tasks</p>
            </div>

            <div className="prompt-section">
              <h2>
                Edit Prompt: <i>Top Matches</i>
              </h2>
              <textarea
                className="prompt-textarea"
                value={matchingPrompt}
                onChange={(e) => setMatchingPrompt(e.target.value)}
                placeholder="Edit top matches prompt..."
                aria-label="Top Matches Prompt"
              />
            </div>
          </div>

          {/* Controls Section */}
          <div className="prompt-controls">
            <div className="switch-container">
              <Switch
                isChecked={optionsState.matchAITasks}
                onChange={() => handleToggle("matchAITasks")}
                label="Use All Prompting (AI Matching)"
                aria-label="Toggle AI Matching"
              />
            </div>

            <div className="switch-container">
              <Switch
                isChecked={optionsState.extendDescription}
                onChange={() => handleToggle("extendDescription")}
                label="Use Expand Description"
                aria-label="Toggle Expand Description"
              />
            </div>

            {optionsState.matchAITasks && (
              <>
                <div className="dropdown-container">
                  <Dropdown
                    title="Match"
                    options={modeOptions}
                    multiple={false}
                    up
                    value={[optionsState.mode]}
                    onChange={(values) =>
                      setOptionsState((prev) => ({ ...prev, mode: values[0] }))
                    }
                    buttonClassName={"save-button"}
                    aria-label="Match Mode Selection"
                  />
                </div>

                {optionsState.mode === "With Exact number (recommended)" && (
                  <div className="input-container">
                    <label htmlFor="exact-num" className="exact-num-label">
                      Exact Number
                    </label>
                    <input
                      id="exact-num"
                      type="number"
                      value={optionsState.exactNum}
                      onChange={(e) =>
                        setOptionsState((prev) => ({
                          ...prev,
                          exactNum: Number(e.target.value),
                        }))
                      }
                      className="input-field"
                      aria-label="Exact Number"
                    />
                  </div>
                )}

                {optionsState.mode === "With Min/Max" && (
                  <>
                    <div className="input-container">
                      <label htmlFor="min-num" className="min-max-label">
                        Min
                      </label>
                      <input
                        id="min-num"
                        type="number"
                        value={optionsState.minNum}
                        onChange={(e) =>
                          setOptionsState((prev) => ({
                            ...prev,
                            minNum: Number(e.target.value),
                          }))
                        }
                        className="input-field"
                        aria-label="Minimum Number"
                      />
                    </div>
                    <div className="input-container">
                      <label htmlFor="max-num" className="min-max-label">
                        Max
                      </label>
                      <input
                        id="max-num"
                        type="number"
                        value={optionsState.maxNum}
                        onChange={(e) =>
                          setOptionsState((prev) => ({
                            ...prev,
                            maxNum: Number(e.target.value),
                          }))
                        }
                        className="input-field"
                        aria-label="Maximum Number"
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>

          {/* Save Button */}
          <div className="save-button-container">
            <button
              className="save-button"
              onClick={handleSaveVersion}
              disabled={isCreating || !!nameError}
              aria-disabled={isCreating || !!nameError}
            >
              {isCreating ? "Saving..." : "Save New Preset"}
            </button>
          </div>
        </div>

        {/* Notification Center */}
        {isNotificationShown && (
          <div
            className={`notification-container ${
              isNotificationCollapsed ? "collapsed" : ""
            }`}
          >
            <NotificationCenter
              isNotificationCollapsed={isNotificationCollapsed}
              toggleCollapse={toggleNotificationCenter}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PresetsPage;
